<template>
    <div>
        <div class="box">
            <b-tabs
                v-model="activeTab"
                @input="
                    value => {
                        openOnce[value] = true
                    }
                "
            >
                <b-tab-item
                    v-for="tab in tabs"
                    :key="`tab_${tab.index}`"
                    :label="tab.label"
                    :icon="tab.icon"
                    :value="tab.type"
                    :disabled="tab.disabled"
                >
                    <keyword-stats v-if="tab.index === 0 && openOnce[tab.index]" />
                    <keyword-table v-if="tab.index === 1 && openOnce[tab.index]" />
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import KeywordStats from '@/components/Admin/Keyword/KeywordStats.vue'
    import KeywordTable from '@/components/Admin/Keyword/KeywordTable.vue'

    export default {
        name: 'KeywordList',
        title: 'layout.keywords',
        components: {
            KeywordStats,
            KeywordTable
        },
        data: function () {
            return {
                activeTab: 1,
                openOnce: {
                    1: true
                },
                counts: null,
                tabs: [
                    {
                        type: 'stats',
                        label: this.$t('global.stats'),
                        icon: 'signal',
                        disabled: false,
                        index: 0
                    },
                    {
                        type: 'keywords',
                        label: this.$t('global.keywords'),
                        icon: 'feather',
                        disabled: false,
                        index: 1
                    }
                ]
            }
        }
    }
</script>
